<template>
  <div class="w-full overflow-auto">
    <div class="max-w-screen-lg">
      <div>
        <report-list
          v-if="hasFinancialReportAccess"
          :heading="$tk('Reports.Invoice', true)"
        >
          <report-link
            v-for="(report, index) in invoiceReports"
            :key="index"
            :report="report"
          />
        </report-list>
        <report-list
          v-if="transactionReports.length > 0"
          :heading="$tk('Reports.BalanceControlAndTransactions', true)"
        >
          <report-link
            v-for="(report, index) in filter(transactionReports, r => r.available)"
            :key="index"
            :report="report"
          />
        </report-list>
      </div>

      <div class="">
        <div v-if="targitReports && Object.keys(targitReports).length > 0">
          <div
            v-for="(category, key) in targitReports"
            :key="key"
          >
            <report-list
              v-if="getTargitReports(category).length > 0"
              :heading="key"
            >
              <button
                v-for="(report, index) in getTargitReports(category)"
                :key="index"
                class="flex flex-row px-4 py-2 bg-white gap-2 hover:shadow-md"
                @click="open(report.id)"
              >
                <div class="flex-none w-12 my-auto">
                  <img
                    :src="targitReportImageUrl(report.id)"
                    alt=""
                    class="object-scale-down"
                  />
                </div>
                <div class="flex-auto my-auto">
                  <div class="text-base text-left">{{ report.title }}</div>
                  <div class="text-xs text-left">{{ report.description }}</div>
                </div>
              </button>
            </report-list>
          </div>
        </div>
      </div>

      <report-list
        v-if="baseReports.length > 0 && hasBaseReportAccess"
        :heading="$tk('Reports.BaseData', true)"
      >
        <report-link
          v-for="(report, index) in baseReports"
          :key="index"
          :report="report"
        />
      </report-list>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { filter } from 'lodash'
import { getConfig } from "@/config"
import http from "@/utilities/http"
import TokenService from "@/services/TokenService"

import ReportLink from "../components/ReportLink"
import ReportList from '../components/ReportList'

const config = getConfig();

export default {
  name: 'Reports',
  data() {
    return {
      targitReports: []
    }
  },
  computed: {
    ...mapGetters([
      "customer",
      "location",
      "user"
    ]),
    invoiceReports() {
      return [
        { "path": "invoices/customer", text: this.$tk('Reports.InvoicesFromSR', true) },
        { "path": "purchases", text: this.customer.invoiceTypeIOId > 0 ? this.$tk('Reports.ReverseInvoice', true) : this.$tk('Reports.InvoiceBasisFromSR', true) }
      ]
    },
    hasBaseReportAccess() {
      return this.user.allowStandardReports
    },
    hasFinancialReportAccess() {
      return this.user.allowFinancialReports
    },
    transactionReports() {
      return [
        { "path": "commisions", text: this.$tk('Reports.BalanceControlCommission', true), available: this.commissionAvailable },
        { "path": "pooling", text: this.$tk('Reports.BalanceControlPooling', true), available: this.poolingAvailable }
        // { "path": "transactions", text: this.$tk('Reports.Transactions', true), available: true},
      ]
    },
    baseReports() {
      return this.location.isPooling ? [
        { "path": "users", text: this.$tk('Reports.Users', true), icon: 'user' },
        { "path": "locations", text: this.$tk('Reports.Locations', true) },
      ] : []
    },
    poolingAvailable() {
      if (this.user.reportLevel === 1) {
        return this.customer.isPooling
      }
      return this.location.isPooling
    },
    commissionAvailable() {
      if (this.user.reportLevel === 1) {
        return this.customer.isCommission
      }
      return this.location.isCommission
    }
  },
  components: {
    ReportLink, ReportList
  },
  methods: {
    filter,
    async open(id) {
      const report = await http.post("report", { id })
      window.open(report.url)
    },
    targitReportImageUrl(id) {
      // biome-ignore lint/style/useTemplate: <explanation>
      return config?.apiUrl + 'ReportIcon?id=' + encodeURIComponent(id) + '&authtoken=' + encodeURIComponent(TokenService.getToken())
    },
    getTargitReports(reports) {
      // console.debug(reports)
      return reports.filter(r => (r => r.isFinancial === false || r.isFinancial === null) || (r.isFinancial && this.hasFinancialReportAccess))
    },
  },
  async created() {
    this.targitReports = await http.get("Reports")
  }
}
</script>
