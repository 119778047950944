<template>
	<div class="mb-8">
		<h2 class="mb-2 text-lg font-medium text-gray-900">{{ heading }}</h2>
		<div class="w-full grid sm:grid-cols-2 md:grid-cols-3 gap-3">
			<slot />
		</div>
	</div>
</template>
<script>
export default {
	name: 'ReportList',
	props: {
		heading: { type: String, default: '' }
	}
}
</script>